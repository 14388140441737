import { post, put } from '../http'

const scfBusinessApi = {
  //借款方授信添加
  addBorrowerCreditApi(params) {
    return post('/business/borrowerCredit/addBorrowerCredit', params)
  },
  //借款方授信列表
  getborrowerCreditListApi(params) {
    return post('/business/borrowerCredit/getborrowerCreditPageList', params)
  },
  //借款方授信详情
  getBorrowerCreditDetailApi(params) {
    return post('/business/borrowerCredit/getBorrowerCreditDetail', params)
  },
  //借款方授信修改
  modifyBorrowerCreditApi(params) {
    return post('/business/borrowerCredit/modifyBorrowerCredit', params)
  },
  //借款方授信禁用or启用
  modifyBorrowerStateApi(params) {
    return post('/business/borrowerCredit/modifyBorrowerState', params)
  },
  //借款方授信禁用or启用
  forbiddenApi(params) {
    return post('/business/product/forbidden', params)
  },
  //查所有项目
  getAllProjectByBusRoleApi(params) {
    return post('/business/enterpriseCredit/getAllProjectByBusRole', params)
  },
  //获取全项目信息
  getAllProjectApi(params) {
    return post('/business/enterpriseCredit/getAllProject', params)
  },
  //核心企业授信列表
  getenterpriseCreditListApi(params) {
    return post(
      '/business/enterpriseCredit/getEnterpriseCreditPageList',
      params
    )
  },
  //核心企业授信禁用
  modifyEnterpriseStateApi(params) {
    return post('/business/enterpriseCredit/modifyEnterpriseState', params)
  },
  //核心企业授信添加
  addCreditApi(params) {
    return post('/business/enterpriseCredit/addCredit', params)
  },
  //核心企业授信编辑
  modifyEnterpriseCreditApi(params) {
    return post('/business/enterpriseCredit/modifyEnterpriseCredit', params)
  },
  //核心企业授信详情
  getEnterpriseCreditDetailApi(params) {
    return post('/business/enterpriseCredit/getEnterpriseCreditDetail', params)
  },

  //产品管理中根据项目名称查资方和核心企业信息
  getProductEnterpriseCreditDetailApi(params) {
    return post('/business/enterpriseCredit/getEnterpriseCreditDetail', params)
  },
  //获取全项目信息
  getAllProjectSignConfigApi(params) {
    return post('/business/enterpriseCredit/getAllProject', params)
  },
  //获取某项目下全产品信息
  getProjectProductsApi(params) {
    return post('/business/product/getProjectProducts', params)
  },
  //产品授信添加
  addproductApi(params) {
    return post('/business/product/addproduct', params)
  },
  //产品授信列表
  getproductListApi(params) {
    return post('/business/product/getProductPageList', params)
  },
  //产品授信修改
  modifyProductApi(params) {
    return post('/business/product/modifyProduct', params)
  },
  //产品授信详情
  getAllEnterpriseApi(params) {
    return post('/business/product/getproductDetail', params)
  },
  //产品授信禁用
  forbiddenApi(params) {
    return post('/business/product/forbidden', params)
  },
  //产品授信启用
  productEnableApi(params) {
    return post('/business/product/productEnable', params)
  },
  //字典信息查询
  findDicLtApi(params) {
    return post('/business/dictionary/findDicLt', params)
  },
  //文件上传
  fileUploadApi(params) {
    return post('/business/resourcefile/upload', params, true, 5000, 'apc')
  },
  //下载文件流
  fileDownloadApi(params) {
    return post(
      '/business/resourcefile/downloadFileToBase64',
      params,
      true,
      50000,
      'apc'
    )
  },
  //资源文件删除
  fileDeleteApi(params) {
    return post('/business/resourcefile/delete', params, true, 5000, 'apc')
  },
  //资源文件删除
  resourceFiledeleteSApi(params) {
    return post('/business/resourcefile/delete', params)
  },
  //公用接口-资源文件上传
  resourceFileUploadApi(params) {
    return post('/business/resourcefile/upload', params)
  },
  //陕建批量导入：文件上传后得解析接口
  resourceBatchFileUploadApi(params) {
    return post('/business/ticket/publish/analysis/v1', params)
  },
  //陕建：批量导入添加功能
  queryAddBatchApi(params) {
    return post('/business/ticket/publish/addBatch/v1', params)
  },
  //合同模板分页列表
  contractTemplatefindPageListApi(params) {
    return post('/business/contracttemplate/findPageList', params)
  },
  //合同模板详情
  contractTemplatefindDetailApi(params) {
    return post('/business/contracttemplate/findDetail', params)
  },
  //合同模板新增
  contractTemplateAddApi(params) {
    return post('/business/contracttemplate/add', params)
  },
  //合同模板上传
  contractTemplateUploadApi(params) {
    return post('/business/contracttemplate/analysis', params)
  },
  //合同模板修改
  contractTemplateUpdateApi(params) {
    return post('/business/contracttemplate/update', params)
  },
  //合同模板删除
  contractTemplateDeleteApi(params) {
    return post('/business/contracttemplate/delete', params)
  },
  // 签约配置合同模板获取
  findContractTemplatesApi(params) {
    return post('/business/signconfig/findContractTemplates', params)
  },
  // 签约配置分页列表
  findPageListApi(params) {
    return post('/business/signconfig/findPageList', params)
  },
  // 签约配置新增
  signConfigAddApi(params) {
    return post('/business/signconfig/add', params)
  },
  // 签约配置详情
  signConfigFindDetailApi(params) {
    return post('/business/signconfig/findDetail', params)
  },
  // 签约配置修改
  signConfigUpdateApi(params) {
    return post('/business/signconfig/update', params)
  },
  // 签约配置删除
  signConfigDeleteApi(params) {
    return post('/business/signconfig/delete', params)
  },
  //票付通
  queryListApi(params) {
    return post('/pft/api/statement/queryList', params)
  },
  //富民
  listApi(params) {
    return post('/account/apiStatement/list', params)
  },

  //商票交易列表
  getPageListApi(params) {
    return post('/business/split/transfer/getPageList', params)
  },
  //商票交易详情
  getDetailApi(params) {
    return post('/business/split/transfer/getDetail', params)
  },
  //商票交易转让详情
  getTransferDetailApi(params) {
    return post('/business/split/transfer/getTransferDetail', params)
  },
  //运营-票据背面
  getAdminTicketBackApi(params) {
    return post('/business/split/transfer/getAdminTicketBack', params)
  },
  //借款方-票据背面
  getTicketBackApi(params) {
    return post('/business/split/transfer/getTicketBack', params)
  },
  //转让-转让列表
  getTransferLtApi(params) {
    return post('/business/split/transfer/getTransferLt', params)
  },
  //转让方列表信息查询
  getTransferorListApi(params) {
    return post('/business/transferor/getTransferorList', params)
  },
  //转让新增
  addTransferApi(params) {
    return post('/business/split/transfer/addTransfer', params)
  },
  //转让审核
  auditTransferApi(params) {
    return post('/business/split/transfer/auditTransfer', params)
  },
  //转让重新提交
  reAddTransferApi(params) {
    return post('/business/split/transfer/reAddTransfer', params)
  },
  //转让撤销提交
  revokeTransferApi(params) {
    return post('/business/split/transfer/revokeTransfer', params)
  },
  //转让撤销重新提交
  reRevokeTransferApi(params) {
    return post('/business/split/transfer/reRevokeTransfer', params)
  },
  //转让撤销提交审核
  auditRevokeTransferApi(params) {
    return post('/business/split/transfer/auditRevokeTransfer', params)
  },
  //取消撤销转让
  cancelRevokeTransferApi(params) {
    return post('/business/split/transfer/cancelRevokeTransfer', params)
  },
  //签收
  signTransferApi(params) {
    return post('/business/split/transfer/signTransfer', params)
  },
  //签收审核
  auditSignTransferApi(params) {
    return post('/business/split/transfer/auditSignTransfer', params)
  },

  //字典查询批量
  dictionaryApi(params) {
    return post(
      `/business/dictionary?typeList=${params.typeList[0]},adsf`,
      params
    )
  },

  //查询审核历史
  auditHistoryApi(params) {
    return post(`/business/audit/auditHistory`, params)
  },

  //展示原票详情
  getTicketDetailApi(params) {
    return post(`/business/ticket/publish/getDetail`, params)
  },

  //待办进入所需接口参数IDS查询
  getAuditNeedIdsApi(params) {
    return post(`/business/split/transfer/getAuditNeedIds`, params)
  },
  //首页待办事项查询
  todoQueryApi(params) {
    return post(`/business/audit/todoQuery`, params)
  },
  //运营方首页额度查询
  getTotalAmountApi(params) {
    return post(`/business/ticketApply/getTotalAmount`, params)
  },

  //富民账户交易统计
  getFmStatisticsApi(params) {
    return post(`/statistics/fumin`, params)
  },
  //用户统计
  companyCountsApi(params) {
    return post(`/statistics/companyCounts`, params)
  },
  //省级区域交易额统计
  provincesAndCitiesAmountApi(params) {
    return post(`/statistics/provincesAndCitiesAmount`, params)
  },
  //平台交易总额
  busTomalAmountApi(params) {
    return post(`/statistics/busTomalAmount`, params)
  },
  //实时平台交易额统计
  mtRealAmountApi(params) {
    return post(`/statistics/mtRealAmount`, params)
  },
  //京东渠道报价
  queryQuoteApi(params) {
    return post(`/statistics/draftList`, params)
  },
  //富民渠道报价
  fuminIcipApi(params) {
    return post(`/statistics/fumin-icip`, params)
  },
  //统计面板-撮合交易额统计
  actualValueApi(params) {
    return post(`/statistics/draft/actualValue`, params)
  },
  //富民秒贴交易额
  dealAmountApi(params) {
    return post(`/statistics/seconds/sticker/dealAmount`, params)
  },
  //线下票据登记总交易金额
  ticketAmountApi(params) {
    return post(`/statistics/offline/ticketAmount`, params)
  },
  //区域票据价格
  ticketPriceApi(params) {
    return post(`/statistics/ticketPrice`, params)
  },

  //首页发行统计
  publishStatisticsApi(params) {
    return post(`/business/ticket/publish/statistics/v1`, params)
  },

  //还款记录列表
  repaymentRecordListApi(params) {
    return post(`/business/v1/finance/repayment/repaymentRecordList`, params)
  },
  //借款方-还款列表
  repaymentListApi(params) {
    return post(`/business/v1/finance/repayment/repaymentList`, params)
  },
  //还款登记列表
  repaymentRegisterListApi(params) {
    return post(`/business/v1/finance/repayment/repaymentRegisterList`, params)
  },
  //还款记录-导出全部
  repaymentRecordExportAllApi(params) {
    return post(
      `/business/v1/finance/repayment/repaymentRecord/exportAll`,
      params
    )
  },
  //还款登记-导出全部
  repaymentRegisterExportAllApi(params) {
    return post(
      `/business/v1/finance/repayment/repaymentRegister/exportAll`,
      params
    )
  },
  //还款列表-导出全部
  repaymentExportAllApi(params) {
    return post(
      `/business/v1/finance/repayment/repaymentList/exportAll`,
      params
    )
  },
  //还款登记
  repaymentRegisterApi(params) {
    return post(`/business/v1/finance/repayment/repaymentRegister`, params)
  },
  //还款页查询
  repaymentQueryApi(params) {
    return post(`/business/v1/finance/repayment/repaymentQuery`, params)
  },
  //还款
  repaymentApi(params) {
    return post(`/business/v1/finance/repayment/repayment`, params)
  },
  //确认还款
  confirmRepaymentApi(params) {
    return post(`/business/v1/finance/repayment/confirmRepayment`, params)
  },
  //还款登记查询
  repaymentRegisterQueryApi(params) {
    return post(`/business/v1/finance/repayment/repaymentRegisterQuery`, params)
  },
  //首页-还款日期
  getRepaymentDatesApi(params) {
    return post(`/business/v1/finance/repayment/getRepaymentDates`, params)
  },
  //融资单详情信息查询
  getFinanceDetailApi(params) {
    return post(`/business/v1/finance/getDetail`, params)
  },

  //单据列表
  documentListApi(params) {
    return post(`/business/v1/bill/payableList`, params)
  },
  //单据管理添加
  documentAddApi(params) {
    return post(`/business/v1/bill/add`, params)
  },
  //融资-添加合同
  addContractApi(params) {
    return post(`/business/v1/bill/addContract`, params)
  },

  //批量导入ecxel(应收-核心企业)
  importBillApi(params) {
    return post(`/business/v1/bill/importBill`, params)
  },
  //批量导入ecxel(应收-除核心企业)
  importBillAllApi(params) {
    return post(`/business/v1/bill/importBillAll`, params)
  },
  //批量导入ecxel(应付-核心企业)
  importBillPayApi(params) {
    return post(`/business/v1/bill/importBillPay`, params)
  },
  //批量导入ecxel(应付-除核心企业)
  importBillAllPayApi(params) {
    return post(`/business/v1/bill/importBillAllPay`, params)
  },
  //单据编辑
  billModifyApi(params) {
    return post(`/business/v1/bill/updateBill`, params)
  },
  //单据删除
  billDeleteApi(params) {
    return post(`/business/v1/bill/deleteBill`, params)
  },
  //详情单据信息
  billDetailApi(params) {
    return post(`/business/v1/bill/detail`, params)
  },
  //详情合同信息
  contractDetailApi(params) {
    return post(`/business/v1/bill/contractDetail`, params)
  },
  //详情发票信息
  invoiceDetailApi(params) {
    return post(`/business/v1/bill/invoiceDetail`, params)
  },
  //全部导出ecxel(应收-核心企业)
  exportDocketApi(params) {
    return post(`/business/v1/bill/export`, params)
  },
  //全部导出ecxel(应收-除核心企业)
  exportDocketAllApi(params) {
    return post(`/business/v1/bill/exportAll`, params)
  },
  //全部导出ecxel(应付-核心企业)
  exportPayApi(params) {
    return post(`/business/v1/bill/exportPay`, params)
  },
  //全部导出ecxel(应付-除核心企业)
  exportPayAllApi(params) {
    return post(`/business/v1/bill/exportAllPay`, params)
  },

  //首页-金融产品总览(除了借款方)
  financeProductsApi(params) {
    return post(`/business/v1/finance/overview/financeProducts`, params)
  },
  //首页-金融产品总览(借款方)
  financeProductBorrowerApi(params) {
    return post(`/business/product/financeProduct`, params)
  },
  //首页-经办事项
  doneQueryApi(params) {
    return post(`/business/audit/doneQuery`, params)
  },

  //首页-融资单列表查询
  borrowerFinanceListApi(params) {
    return post(`/business/v1/finance/list`, params)
  },

  //查询还款计划
  repaymentPlanQueryApi(params) {
    return post(`/business/v1/finance/repaymentPlan/query`, params)
  },

  //还款计划试算
  repaymentPlanCreateApi(params) {
    return post(`/business/v1/finance/repaymentPlan/pre/create`, params)
  },

  //融资单单据信息查询
  financeBillListApi(params) {
    return post(`/business/v1/finance/bill/list`, params)
  },
  //融资申请流程
  getFinanceProcessApi(params) {
    return post(`/business/v1/finance/getProcess`, params)
  },
  //待放款列表
  pendingLoanListApi(params) {
    return post(`/business/v1/finance/loan/pending/page`, params)
  },
  //待放款导出
  pendingExportListApi(params) {
    return post(`/business/v1/finance/loan/pending/export`, params)
  },
  //已放款列表
  loanRecordListApi(params) {
    return post(`/business/v1/finance/loan/record/page`, params)
  },
  //已放款列表导出
  loanRecordExportListApi(params) {
    return post(`/business/v1/finance/loan/record/export`, params)
  },
  //放款
  loanApi(params) {
    let url = `/business/v1/finance/${params.financeId}/loan`
    delete params.financeId
    return put(url, params)
  },
  //融资单确认提交校验
  financeConfirmCheckApi(params) {
    return post(`/business/v1/finance/confirmCheck`, params)
  },
  //资金通道查询
  getCapitalChannelApi(params) {
    return post(`/business/v1/finance/getCapitalChannel`, params)
  },
  //融资单据附件-合同列表
  getContractListApi(params) {
    return post(`/business/v1/finance/bill/annex/getContractList`, params)
  },
  //融资单据附件-发票列表
  getInvoiceListApi(params) {
    return post(`/business/v1/finance/bill/annex/getInvoiceList`, params)
  },

  //供应商管理列表
  getSupplierListApi(params) {
    return post(`/base/supplier/list`, params)
  },
  //添加供应商
  getSupplierAddApi(params) {
    return post(`/base/supplier/add`, params)
  },
  //修改供应商
  getSupplierUpdateApi(params) {
    return post(`/base/supplier/update`, params)
  },
  //删除供应商
  getSupplierDeleteApi(params) {
    return post(`/base/supplier/delete`, params)
  },
  //导出供应商全部
  getSupplierExportAllApi(params) {
    return post(`/base/supplier/exportAll`, params)
  },
  //批量导入供应商
  getSupplierImportBatchApi(params) {
    return post(`/base/supplier/importBatch`, params)
  },
  //添加企业业务角色
  getAddOrgRoleApi(params) {
    return post(`/base/v1/org/addOrgRole`, params)
  },
  //查询企业业务角色
  getQueryOrgRoleApi(params) {
    return post(`/base/v1/org/queryOrgRole`, params)
  },
}
export default scfBusinessApi
